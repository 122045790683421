const tokenTableName = 'Authorization';

export function getAccessToken() {
    return localStorage.getItem(tokenTableName)
}


export function setAccessToken(accessToken) {
    return localStorage.setItem(tokenTableName, accessToken)
}


export function removeAccessToken() {
    return localStorage.removeItem(tokenTableName)
}
