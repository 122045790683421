import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './icons' 
import './plugins';
import '@/layouts/export';
import BaiduMap from 'vue-baidu-map'

import moment from 'moment';
Vue.use(require('vue-moment'));
Vue.prototype.moment = moment;


Vue.use(BaiduMap, {
    ak: 'BzWWvSGWuMhflATBSQpleRVheumqVK82'
})


Vue.config.productionTip = false;
//ʱ���ʽ��
Vue.filter('DateFormat', function (dateStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return moment(dateStr).format(pattern);
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

