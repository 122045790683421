const setting =
{
    title: '壹米',
    loadingText: "加载中...",
    messageDuration: 3000,
    copyright: '壹米人力资源（深圳）有限公司',
    //是否显示页面底部自定义版权信息
    footerCopyright: true,
    //缓存路由的最大数量
    keepAliveMaxNum: 99,
    //token名称
    tokenName: 'Authorization',
    //intelligence和all两种方式，前者后端权限只控制permissions不控制view文件的import（前后端配合，减轻后端工作量），all方式完全交给后端前端只负责加载
    authentication: 'intelligence',
    //是否开启登录拦截
    loginInterception: true,
    //是否显示顶部进度条
    progressBar: true,
    //token失效回退到登录页时是否记录本次的路由
    recordRoute: true,
    //不经过token校验的路由
    routesWhiteList: ['/login'],

    //vertical布局时是否只保持一个子菜单的展开
    uniqueOpened: false,
    //vertical布局时默认展开的菜单path，使用逗号隔开建议只展开一个
    defaultOopeneds: ['/rbac', '/Position','/Material'],
}
module.exports = setting
