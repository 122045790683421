import request from '@/network/request';


/**
 * ȫ���˵�
 */
export async function getModules() {
    return request.get('/Module/GetModules');
}


/**
 * ���Ӳ˵�
 * @param {any} data
 */
export async function addModule(data) {
    //console.log('[addModule]' + JSON.stringify(data));
    return request.post('/Module/Add', data);
}


/**
 * �༭�˵�
 * @param {any} data
 */
export async function editModule(data) {
    //console.log('[editModule]' + JSON.stringify(data));
    return request.post('/Module/Edit', data);
}


/**
 * ɾ���˵�
 * @param {any} data
 */
export async function deleteModule(data) {
    //console.log('[deleteModule]' + JSON.stringify(data));
    return request.delete('/Module/Reomve', data);
}

/**
 * �����û���ID��Ȩģ��
 * @param {any} data
 */
export async function permitByModules(data) {
    //console.log('[permitByModules]' + JSON.stringify(data));
    return request.post('/Permissions/PermitByModules', data);
}


/**
 * �����û���ID��ȡģ��
 * @param {any} data
 */
export async function getModulesByGroupId(data) {
    //console.log('[getModulesByGroupId]' + JSON.stringify(data));
    return request.get('/Permissions/GetModulesByGroupId', data);
}



