/**
** 路由控制
**/
import menu from './menu';
import { asyncRoutes, constantRoutes } from '@/router';
import { getRouterList } from '@/api/router';
import { convertRouter, filterAsyncRoutes } from '@/utils/handleRoutes';

const state = () => ({
    routes: []
})

const getters = {
    routes: (state) => state.routes
}

const mutations = {
    setRoutes(state, routes) {
        state.routes = constantRoutes.concat(routes);
    },
    setAllRoutes(state, routes) {
        state.routes = constantRoutes.concat(routes);
    }
}

const actions = {
    async setRoutes({ commit }, groups) {
        //动态路由与后台路由拼接 
        let allAsyncRoutes = [...asyncRoutes].concat(menu.state.menus);
        //console.log('[allAsyncRoutes]' + JSON.stringify(allAsyncRoutes));
        //根据用户权限码过滤路由
        const finallyAsyncRoutes = filterAsyncRoutes(
            [...allAsyncRoutes],
            [...groups]
        );
        //console.log('[finallyAsyncRoutes]' + JSON.stringify(finallyAsyncRoutes));
        commit('setRoutes', finallyAsyncRoutes);
        return finallyAsyncRoutes;
    },
    async setAllRoutes({ commit }) {
        let { data } = getRouterList();
        data.push({ path: '*', redirect: '/404', hidden: true });
        let accessRoutes = convertRouter(data);
        commit('setAllRoutes', accessRoutes);
        return accessRoutes;
    }
}


export default { state, getters, mutations, actions, namespaced: true }
