<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
        mounted() { },
    }
</script>

<style lang="scss">
    #app {
        height: 100vh;
    }
</style>