import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import settings from './modules/settings'
import routes from './modules/routes'
import menu from './modules/menu'
import errorLog from './modules/errorLog'
import tabsBar from './modules/tabsBar'
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user,
        settings,
        routes,
        menu,
        errorLog,
        tabsBar
    }
});
export default store;