/***
 * Author：Xiongor 
 * Des：Login User State Operation
 ***/

import { resetRouter } from '@/router';
import Vue from 'vue';
import { login, getUserInfo } from '@/api/user';
import { getAccessToken, setAccessToken, removeAccessToken } from '@/utils/accessToken'


const state = {
    token: getAccessToken(),
    username: '',
    groups: [],
    avatar: require('@/assets/images/avatar.png'),
};

const getters = {
    token: state => state.token,
    username: state => state.username,
    groups: state => state.groups,
    avatar: (state) => state.avatar,
};

const mutations = {
    setToken(state, token) {
        //console.log('[Vuex mutations setToken]' + JSON.stringify(token));
        state.token = token;
        setAccessToken(token);
    },
    setUsername(state, username) {
        state.username = username
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    setGroups(state, groups) {
        //console.log('[Vuex mutations setGroups]' + JSON.stringify(groups));
        state.groups = groups
    }
};
const actions = {
    setGroups({ commit }, groups) {
        commit('setGroups', groups)
    },
    async login({ commit }, user) {
        var data = await login(user);
        //console.log('[Vuex Async Login Then]' + JSON.stringify(data));
        if (!data.isSucceed) {
            commit('setToken', '');
            Vue.prototype.$baseMessage(`${data.message}`, 'error');
            return false;
        } else {
            Vue.prototype.$baseNotify(`欢迎登录`);
            commit('setToken', data.result);
            return data;
        }
    },
    async getUserInfo({ commit }) {
        var data = await getUserInfo();
        //console.log('[Vuex Async getUserInfo Then]' + JSON.stringify(data));
        if (!data.isSucceed) {
            Vue.prototype.$baseMessage(`${data.message}`, 'error');
            return false;
        } else {
            commit('setGroups', data.result.groups);
            commit('setUsername', data.result.name);
            //commit('setAvatar', data.result.avatar);
            return data.result.groups;
        }
    },
     async logout({ dispatch }) {
       // await logout(state.accessToken)
        await dispatch('resetAccessToken')
        await resetRouter()
    },
    resetAccessToken({ commit }) {
        commit('setGroups', []);
        commit('setToken', '');
        removeAccessToken();
    },
};

const store = {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
export default store;