import Vue from 'vue';
import axios from 'axios';
//import Qs from 'qs';
import { Url } from '@/config';
import { getAccessToken, removeAccessToken } from '@/utils/accessToken'
let loading;

const instance = axios.create({
    baseURL: Url,
    timeout: 13000
});
//请求拦截
instance.interceptors.request.use(config => {
    //console.log('[Interceptors Request]token=' + getAccessToken());
    if (localStorage.Authorization) {
        /*设置统一的header*/
        config.headers.Authorization = getAccessToken();
    }
    loading = Vue.prototype.$baseLoading();
    return config;
}, error => {
    return Promise.reject(error);
});

//响应拦截
instance.interceptors.response.use(res => {
    //console.log('[Interceptors Response]' + JSON.stringify(res));
    if (loading) loading.close();
    return res;
}, error => {
    if (loading) loading.close(); 
    const { status } = error.response;
    switch (status) {
        case 403:
            Vue.prototype.$baseMessage("登录状态已过期，请重新登录", "warning");
            localStorage.removeItem('Authorization');
            location.href = '/login';
            break;
        case 401:
            Vue.prototype.$baseMessage("请先登录", "warning");
            removeAccessToken();
            location.href = '/login';
            break;
        case 405:
            Vue.prototype.$baseMessage(error, "error");
            break;
    }
    return Promise.reject(error);
})



export default {
    instance,
    get: function (url = '', data = {}) {
        return new Promise(function (resolve, reject) {
            instance.get(url, { params: data })
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    post: function (url = "", data = {}) {
        return new Promise(function (resolve, reject) {
            instance.post(url, data)
                .then(function (response) {
                    resolve(response.data);
                    return response.data;
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    delete: function (url = "", params = {}) {
        return new Promise(function (resolve, reject) {
            instance.delete(url, { params: params })
                .then(function (response) {
                    resolve(response.data);
                    return response.data;
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    put: function (url = "", params = {}) {
        return new Promise(function (resolve, reject) {
            instance.put(url, { params: params })
                .then(function (response) {
                    resolve(response.data);
                    return response.data;
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    upload: function (url, files) {
        return new Promise(function (resolve, reject) {
            instance.post(url, files, { headers: { "Content-type": "multipart/form-data" } })
                .then(function (response) {
                    resolve(response.data);
                    return response.data;
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }
}