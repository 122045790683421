import request from '@/network/request';

/*
 * 用户登录
 * 参数DATA
{
    passWord: '用户密码',
    name: '用户名称',
}
*/
export async function login(data) {
    return request.post('/Auth/Login', data)
}

/*
 * 用户信息
*/
export async function getUserInfo() {
    return request.post('/User/GetUser');
}

///*
// * 用户列表带授权标识
// * 参数DATA
//{
//    groupId: 用户组ID,
//}
//*/
//export async function getUserInfoByGroupId(data) {
//    return request.post('/User/GetUserInfoByGroupId', data);
//}


/* 
 * 用户列表
 * 参数DATA
{
    pageIndex: 页码,
    pagesize: 页数,
    name: '用户名称',
}
*/
export async function getUserList(data) {
    //console.log('[getUserList]' + JSON.stringify(data));
    return request.post('/User/GetUserList', data);
}

/*
 * 添加用户
 * 参数DATA
{
    name: '用户名称',
    passWord: '用户密码',
    mobile: '手机号码',
    isDisable: 是否禁用
}
*/
export async function doAdd(data) {
    //console.log('[doAdd]' + JSON.stringify(data));
    return request.post('/User/AddUser', data);
}


/*
 * 修改用户
 * 参数DATA
{
    id: 用户ID,
    name: '用户名称',
    passWord: '用户密码',
    mobile: '手机号码',
    isDisable: 是否禁用,
    userGroups:用戶组数组
}
*/
export async function doEdit(data) {
    //console.log('[doEdit]' + JSON.stringify(data));
    return request.post('/User/EditUser', data);
}


/*
 * 禁用用户
 * 参数DATA
{
    id: 用户ID,
    isDisable: 0|1
}
*/
export async function doDelete(data) {
    //console.log('[doDelete]' + JSON.stringify(data));
    return request.post('/User/UpdateDisableUser', data);
}


/*
 * 获取用户组
 * 参数DATA
{
    pageIndex: 页码,
    pagesize: 页数,
    name: '名称',
}
*/
export async function getUserGrop(data) {
    //console.log('[getUserGrop]' + JSON.stringify(data));
    return request.post('/User/GetUserGroupList', data);
}


/*
 * 添加用户组
 * 参数DATA
{
  "name": "组名称",
  "code": "权限码",
  "isDisable": 是否禁用
}
*/
export async function AddUserGrop(data) {
    //console.log('[AddUserGrop]' + JSON.stringify(data));
    return request.post('/User/AddUserGroup', data);
}


/*
 * 编辑用户组
 * 参数DATA
{
  "id":组ID
  "name": "组名称",
  "code": "权限码",
  "isDisable": 是否禁用
}
*/
export async function EditUserGrop(data) {
    //console.log('[EditUserGrop]' + JSON.stringify(data));
    return request.post('/User/EditUserGroup', data);
}


/*
 * 删除用户组
 * 参数 Data
 {
    "groupId":用户组ID
 }
*/
export async function DeleteUserGrop(params) {
    //console.log('[DeleteUserGrop]' + JSON.stringify(params));
    return request.delete('/User/DelUserGroup', params);
}


/*
 * 解除该组全部用户关系
 * 参数 Data
 {
    "groupId":用户组ID
 }
*/
export async function RemoveUserRel(data) {
    //console.log('[RemoveUserRel]' + JSON.stringify(data));
    return request.delete('/User/RemoveUserRel', data);
}
/*
 * 解除该组模块授权关系
 * 参数 Data
 {
    "groupId":用户组ID
 }
*/
export async function RemoveModuelRel(data) {
    //console.log('[RemoveModuelRel]' + JSON.stringify(data));
    return request.delete('/User/RemoveModuelRel', data);
}



/*
 * 添加组对应用户关系
 * 参数 Data
 {
    "groupId":用户组ID,
    "userId": 用户ID
 }
*/
export async function addUserRel(data) {
    //console.log('[AddUserRel]' + JSON.stringify(data));
    return request.post('/User/AddUserRel', data);
}


/*
 * 解除组对应用户关系
 * 参数 Data
 {
    "groupId":用户组ID,
    "userId": 用户ID
 }
*/
export async function removeUserRel(data) {
    //console.log('[RemoveUserRel]' + JSON.stringify(data));
    return request.delete('/User/RemoveUserRel', data);
}