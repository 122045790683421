/***
 * 路由守卫
 * 
 ***/
//import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import VabProgress from 'nprogress';
import 'nprogress/nprogress.css';
import getPageTitle from '@/utils/pageTitle';
import {
    routesWhiteList,
    loginInterception,
    progressBar,
    recordRoute,

} from '@/config'

VabProgress.configure({
    easing: 'ease',
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
})

//2.5.0+ 全局解析守卫
router.beforeResolve(async (to, from, next) => {
    if (progressBar) VabProgress.start();
    let hasToken = store.getters['user/token'];

    if (hasToken) {
        if (to.path === '/login') {
            next();
        } else {
            const hasPermissions = store.getters['user/groups'] && store.getters['user/groups'].length > 0;
            if (hasPermissions) {
                next();
            } else {
                let groups = await store.dispatch('user/getUserInfo');
                if (groups && groups.length > 0) {
                    await store.dispatch('menu/getAndsetMenus');
                    //console.log('[groups]' + JSON.stringify(groups));
                    let accessRoutes = await store.dispatch('routes/setRoutes', groups);
                    accessRoutes.forEach((item) => {
                        router.addRoute(item);
                    });
                    next({ ...to, relpace: true });
                }
                //无授权用户组的用户
                else {
                    next();
                }
                if (progressBar) VabProgress.done();
            }
        }
    }
    else {
        if (routesWhiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            if (recordRoute) {
                next(`/login?redirect=${to.path}`)
            } else {
                next('/login')
            }
        }
        if (progressBar) VabProgress.done();
    }
    document.title = getPageTitle(to.meta.title)
})

router.afterEach(() => {
    if (progressBar) VabProgress.done()
})
