/**
 * Author XiongOr
 * Desc: �˵�ģ��״̬����
 * */

import Vue from 'vue';
import { getModules } from '@/api/menu';
import { formatToTree } from '@/utils';


const state = {
    menus: [],
};

const getters = {
    menus: state => state.menus,
};

const mutations = {
    setMenus(state, menus) {
        //console.log('[Vuex setMenus]' + JSON.stringify(menus));
        state.menus = menus;
    }
};
const actions = {
    setMenus({ commit }, menus) {
        commit('setMenus', menus)
    },
    async getAndsetMenus({ commit }) {
        var data = await getModules();
        if (!data.isSucceed) {
            commit('setMenus', null);
            Vue.prototype.$baseMessage(`${data.message}`, 'error');
            return false;
        } else {
            if (!data.result || data.result.length == 0) return;
            //��Ⱦ����componet
            var renderMenus = [];
            data.result.forEach((item) => {
                const p = item.component;
                item['component'] = () => import(`@/${p}`);
                renderMenus.push(item);
            });
            let treeData = formatToTree(renderMenus);
            //console.log('[treeData]' + JSON.stringify(treeData));
            commit('setMenus', treeData);
            return data.result;
        }
    },
};

const store = {
    namespaced: true,
    getters,
    state,
    mutations,
    actions
}
export default store;