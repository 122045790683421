/**
 * @author 
 * @description all模式渲染后端返回路由
 * @param constantRoutes
 * @returns {*}
 */
export function convertRouter(asyncRoutes) {
    return asyncRoutes.map((route) => {
        if (route.component) {
            if (route.component === 'layouts') {
                route.component = (resolve) => require(['@/layouts'], resolve)
            } else if (route.component === 'empty') {
                route.component = (resolve) =>
                    require(['@/layouts/empty'], resolve)
            } else {
                const index = route.component.indexOf('views')
                const path =
                    index > 0 ? route.component.slice(index) : `views/${route.component}`
                route.component = (resolve) => require([`@/${path}`], resolve)
            }
        }
        if (route.children && route.children.length)
            route.children = convertRouter(route.children)
        if (route.children && route.children.length === 0) delete route.children
        return route
    })
}

/**
 * @author 
 * @description 判断当前路由是否包含权限
 * @param groups
 * @param route
 * @returns {boolean|*}
 */
function hasPermission(groups, route) {
    if (!route.groups) return true;
    return groups.some((g) => {
        if (g.code == 'admin') return true;
        if (!route.groups || route.groups.length == 0) return false;
        var yn = route.groups.some(r => r.code == g.code);
        return yn;
    });
}

/**
 * @description 根据用户权限码拦截路由
 * @param routes 路由数组
 * @param groups 用户权限码 [{code:'admin'},{code:'it'}]
 * @returns {[]}
 */
export function filterAsyncRoutes(routes, groups) {
    const finallyRoutes = []
    routes.forEach((route) => {
        const item = { ...route };
        if (hasPermission(groups, item)) {
            if (item.children) {
                item.children = filterAsyncRoutes(item.children, groups)
            }
            finallyRoutes.push(item)
        }
    });
    return finallyRoutes
}
