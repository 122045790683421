import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layouts'

Vue.use(Router);


//固定模块
export const constantRoutes = [
    {
        name: 'login',
        path: '/login',
        hidden: true,
        component: () => import('@/views/login'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/',
        component: Layout,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import("@/views/home"),
                meta: {
                    title: '控制台',
                    icon: 'home',
                    affix: true,
                    noKeepAlive: false
                },
            },
        ],
    },
    //{
    //    path: '/icon',
    //    component: Layout,
    //    children: [
    //        {
    //            path: '/icon',
    //            name: 'icon',
    //            component: () => import("@/views/icon/index"),
    //            meta: {
    //                title: '常用图标',
    //                icon: 'grin-wink',
    //                affix: false,
    //                noKeepAlive: false
    //            },
    //        },
    //    ],
    //},

    {
        path: '/Position',
        component: Layout,
        name: 'Position',
        meta: { title: '岗位模块', icon: 'bars' },
        children: [
            {
                path: 'Category',
                name: 'Category',
                component: () => import("@/views/Position/Category"),
                meta: {
                    title: '岗位分类',
                    icon: 'bars'
                },
            },
            {
                path: 'Type',
                name: 'Type',
                component: () => import("@/views/Position/Type"),
                meta: {
                    title: '岗位类别',
                    icon: 'bars'
                },
            },
            {
                path: 'List',
                name: 'List',
                component: () => import("@/views/Position/List"),
                meta: {
                    title: '岗位列表',
                    icon: 'bars'
                },
            },
            {
                path: 'Detail',
                name: 'Detail',
                hidden: true,
                component: () => import("@/views/Position/Detail"),
                meta: {
                    title: '岗位详情',
                    icon: 'bars'
                },
            },
            {
                path: 'Apply',
                name: 'Apply',
                component: () => import("@/views/Apply/List"),
                meta: {
                    title: '申请列表',
                    icon: 'bars'
                },
            },
        ],
    }
    , {
        path: '/Material',
        component: Layout,
        name: 'Material',
        meta: { title: '素材模块', icon: 'bars' },
        children: [
            {
                path: 'Carousel',
                name: 'Carousel',
                component: () => import("@/views/Material/Carousel"),
                meta: {
                    title: '轮播图片',
                    icon: 'bars'
                },
            },
            {
                path: 'FAQType',
                name: 'FAQType',
                component: () => import("@/views/Material/Carousel"),
                meta: {
                    title: '常见问题类型',
                    icon: 'bars'
                },
            },
            {
                path: 'FAQ',
                name: 'FAQ',
                component: () => import("@/views/Material/Carousel"),
                meta: {
                    title: '常见问题',
                    icon: 'bars'
                },
            },
        ],
    }
]

//超管模块
const adminRoutes = [{
    path: '/rbac',
    component: Layout,
    redirect: 'noRedirect',
    name: 'RBAC',
    groups: [{ code: 'admin' }],
    meta: { title: '超管模块', icon: 'users-cog' },
    children: [
        {
            path: 'userGroup',
            name: 'UserGroup',
            component: () => import('@/views/RBAC/userGroup'),
            meta: { title: '组管理', icon: "user-friends" },
        },
        {
            path: 'user',
            name: 'User',
            component: () => import('@/views/RBAC/user'),
            meta: { title: '用户管理', icon: "user-edit", affix: false, noKeepAlive: false },
        },
        {
            path: 'menu',
            name: 'Menu',
            component: () => import('@/views/RBAC/menu'),
            meta: { title: '菜单管理', icon: "server" },
        },
        {
            path: 'city',
            name: 'City',
            component: () => import('@/views/Area/City'),
            meta: { title: '城市管理', icon: "server" },
        }
        ,
        {
            path: 'Resume',
            name: 'Resume',
            component: () => import('@/views/Apply/components/Resume'),
            meta: { title: '简历', icon: "server" },
        }
    ],
}]

//自定义模块
export const asyncRoutes = [...adminRoutes];


const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRoutes
});

export function resetRouter() {
    location.reload()
}

export default router;