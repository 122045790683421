/**
 * @description 导出默认主题配置
 */
const theme = {
    //是否国定头部 固定fixed 不固定noFixed
    header: 'noFixed',
    //横纵布局 horizontal vertical
    layout: 'vertical',
    //是否开启主题配置按钮
    themeBar: false,
    //是否显示多标签页
    tabsBar: true,
    //是否显示logo，不显示时设置false，显示时请填写remixIcon图标名称，暂时只支持设置remixIcon
    logo: false,
}
module.exports = theme
